/* eslint-disable prettier/prettier */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function AboutServices() {
  return (
    <MKBox component="section" pt={12}>
      <Container>
        <Grid container item xs={12} lg={10} justifyContent="center" mx="auto" textAlign="center" pb={6} pt={8}>
          <MKTypography variant="h1" mb={3}>
            About Shannon Cyber Services
          </MKTypography>
          <MKTypography variant="h5" color="text">
            Shannon Cyber Services is your trusted partner in cybersecurity and intelligent automation. <br/> Based in the heart of Austin, Texas, we specialize in providing cutting-edge IT consulting services tailored to meet the unique needs of state and local government agencies.
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={10} mx="auto">
          <MKTypography variant="body1" color="text" align="left">
            Our mission is to empower public sector organizations with robust cybersecurity measures and innovative automation solutions that enhance operational efficiency and safeguard critical data. With a team of seasoned experts, we bring a wealth of experience and a commitment to excellence in every project we undertake.
          </MKTypography>
        </Grid>
        <MKBox component="section" py={6} bgColor="light">
          <Grid container item xs={12} lg={10} mx="auto" pb={4}>
            <MKTypography variant="h4" color="text" align="left" mb={2}>
              At Shannon Cyber Services, we understand the complexities and challenges faced by government agencies. <br/> Our comprehensive services include:
            </MKTypography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <SimpleInfoCard
                color="primary"
                icon="security"
                title="Cybersecurity"
                description="Protecting your digital assets with advanced threat detection, risk management, and compliance solutions."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <SimpleInfoCard
                icon="assistant"
                title="Intelligent Automation"
                description="Streamlining processes through AI-driven automation, reducing manual workloads, and improving service delivery."
                direction="center"
              />
            </Grid>
          </Grid>
        </MKBox>
        <Grid container item xs={12} lg={10} mx="auto">
          <MKTypography variant="body2" color="text" align="left" mb={4}>
            We pride ourselves on our client-centric approach, ensuring that each solution is customized to address your specific requirements. Our dedication to quality and innovation makes us a reliable partner in navigating the ever-evolving landscape of technology.
          </MKTypography>
          <MKBox textAlign="center" mt={1}>
          <a href="https://www.cognitoforms.com/ShannonCyberServices/ContactUs" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
            <MKButton variant="contained" color="primary">
              Contact us
            </MKButton>
          </a>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutServices;
