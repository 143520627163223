/* eslint-disable prettier/prettier */
import React from "react";

import { Box, Container, Grid, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const BradyImg="https://imagedelivery.net/I44K_HLbdSFFoBMKswAB4w/c3a6ec11-9be5-4e01-d603-bb80569c8a00/public";
const DennisImg="https://imagedelivery.net/I44K_HLbdSFFoBMKswAB4w/a416624c-6625-47fd-48f5-8fc78f813500/public";
const AliciaImg="https://imagedelivery.net/I44K_HLbdSFFoBMKswAB4w/2f059153-b047-4824-3858-1c79fec0af00/public";
const OlehImg="https://imagedelivery.net/I44K_HLbdSFFoBMKswAB4w/fb6f4d58-3946-4aa9-fb4a-e4d1e86c0300/public";

const MemberCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  margin: "auto",
  maxWidth: 800,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  textAlign: "justify",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    gap: theme.spacing(2),
  },
}));

const Image = styled("img")({
  width: "200px",
  height: "200px",
  objectFit: "cover",
  borderRadius: "0.225em",
  objectPosition: "center",
});

function MeetTheTeam() {
  const teamMembers = [
    {
      name: "Brady Cusack",
      position: "Managing Partner, Chief Executive Officer",
      bio: "Brady Cusack is an experienced technology leader with expertise in enterprise architecture, robotics process automation, and cybersecurity operations. Brady graduated from Towson University with a B.S. in Computer Science focused on Cybersecurity. He then worked as an automation consultant for numerous small businesses where he helped lead implementations at Fortune 500 companies. Brady later founded a small managed service provider, growing it to support two federal agencies and five government contractors.",
      imageUrl: BradyImg,
    },
    {
      name: "Dennis Chen",
      position: "Partner, SVP of Operations",
      bio: "Dennis graduated from Towson University of MD in Information Systems and Business. Dennis brings over 20+ years of experience leading technology services companies with a strong public sector focus such as Evurge Solutions and WorkAway IT. Dennis has decades of experience providing IT solutions and support to commercial and Federal agencies including US Department of Defense, US Department of State, US Department of Commerce, and the US Nuclear Regulatory Commission. He has designed and deployed solutions for database reporting needs, Cybersecurity and forensic analysis facilities, as well as virtual and cloud environments.",
      imageUrl: DennisImg,
    },
    {
      name: "Alicia Auman",
      position: "Sales Lead",
      bio: "Alicia Auman brings experience selling software to state and local government entities. Alicia has over a decade of experience in the education space, spending years working as a resource inside and out of the classroom. Alicia is Shannon Cyber's first point of contact for all services and needs, better enabling them to maximize their customer experience.",
      imageUrl: AliciaImg,
    },
    {
      name: "Oleh Smolikevych",
      position: "Software Engineer",
      bio: "Oleh is a passionate software engineer with a knack for crafting dynamic, user-centric web applications. He brings a proven track record in developing secure, scalable, and lightning-fast software solutions, he thrives on turning complex challenges into elegant, efficient systems. His journey has been fueled by a relentless curiosity and a commitment to continuous learning, always on the lookout for the latest technologies to push the boundaries of innovation and efficiency in software development. Oleh supports Shannon Cybers customers in all of their website and Salesforce needs.",
      imageUrl: OlehImg,
    }
  ];

  return (
    <Box sx={{ flexGrow: 2, padding: 2, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Typography variant="h3" component="h2" justifyContent="center" gutterBottom>
          Meet the Team
        </Typography>
        <Typography variant="body1" paragraph justifyContent="center">
          Our leadership team brings together years of expertise and dedication to drive our vision
          forward.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {teamMembers.map((member) => (
            <Grid item xs={12} md={6} lg={3} key={member.name}>
              <MemberCard>
                <Image src={member.imageUrl} alt={member.name} />
                <Typography gutterBottom variant="h5" component="div">
                  {member.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" marginBottom={1} sx={{textAlign: "center"}}>
                  {member.position}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {member.bio}
                </Typography>
              </MemberCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default MeetTheTeam;
